import React, { useEffect } from 'react'
// import { navigate } from '@reach/router'
import $ from 'jquery'
import regionData from '../data/region-data.json'
import { withPrefix } from "gatsby"

export default function Header({ regionName, slug }) {
    useEffect(() => {
        let selectElem = $(".js-example-placeholder-single");

        //Force set select value to null so the placeholder can be reset
        $('select').val(null);

        selectElem.select2({
            placeholder: slug === undefined ? "SELECT METRO AREA" : "CHANGE METRO AREA",
            minimumResultsForSearch: Infinity
        });

        selectElem.on('select2:opening', function (event) {
            $('select').val(null);
        })

        selectElem.on('select2:select', function (e) {
            // navigate(withPrefix(`/region/${e.target.value}`));
            window.location.href = withPrefix(`aa/${e.target.value}`);
            // $('select').val(null);

        });
    })

    let stateName = '';

    if (regionName) {
        stateName = (
            <>
            <span className="color-grey d-block d-md-inline"> IN THE  </span>
            <span className="d-md-block">
                <span className="font-bold"> {regionName.toUpperCase()}</span>
                <span className="color-grey d-block d-md-inline"> METRO AREA </span>
            </span>
            </>
        );
    }

    return (
        <div className="banner-wrapper">
            <div className="container">
                <h1 className="banner-heading text-center">
                    <span className="color-grey">UNDERSTANDING</span>
                    <span className="font-bold"> HEREDITARY TRANSTHYRETIN AMYLOIDOSIS FOUNDER POPULATIONS</span>
                    {stateName}
                </h1>
                {regionName ? "" : <select className="js-example-placeholder-single js-states form-control" style={{visibility: 'hidden'}}>
                    <option></option>
                    {
                        Object.keys(regionData).map((region) => {
                            return (
                                <option key={region} value={region}>{regionData[region].name}</option>
                            )
                        })
                    }
                </select>
                }
            </div>
        </div>
    )
}

