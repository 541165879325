import React from 'react'
import Layout from './Layout'

export default function Homepage() {
    return (
        <Layout>
            <div className="homepage-inner-wrapper">
                <div className="container homepage-containers">
                    <p className="homepage-content">
                        The genetic mutations responsible for the rare progressive disease hereditary transthyretin (hATTR)
                        amyloidosis can be traced to specific founder populations or geographic areas.<sup>1</sup> In the United States, the
                        most common mutation in the transthyretin protein is p.V142I.<sup>2</sup> This mutation has origins in West Africa
                        and, importantly, 3.9% of African Americans are estimated to harbor this mutation.<sup>3,4</sup>
                    </p>
                </div>
            </div>
            
        </Layout>
    )
}
