import React from "react"
import Footer from "./Footer"
import Header from "./Header"

export default function Layout(props) {
    return (
      <div className="main-wrapper">
        <Header regionName={props.regionName} slug={props.slug}/>
        <div className="homepage-content-wrapper">{props.children}</div>
        <Footer />
      </div>
    )
}