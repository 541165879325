import { Router } from "@reach/router"
import { withPrefix } from "gatsby"
import React from "react"
import Homepage from "../components/Homepage"
import RegionInfo from "../components/RegionInfo"

export default function App() {
  return (
    <Router>
      <Homepage exact path={withPrefix('/')} />
      <RegionInfo exact path={withPrefix('aa/:region')} />
    </Router>
  )
}
