import React from "react";
import Layout from "./Layout";
import hereditary from "../assets/images/hereditary.svg";
import redFlag from "../assets/images/red-flag.svg";
import timely from "../assets/images/timely.svg";
import dataSource from "../data/region-data.json";

export default function RegionInfo(props) {
  let regionData = dataSource[props.region] || [];
  if(regionData.length === 0) {
    window.location.href = '/';
    return null;
  }
  
  return (
    <Layout regionName={regionData.name} slug={props.region}>
      <div className="test-container">
        <div className="container">
          <p className="homepage-content mb-30-40">

            The genetic mutations responsible for the rare progressive disease hereditary transthyretin (hATTR)
            amyloidosis can be traced to specific founder populations or geographic areas.<sup>1</sup> In the United States, the
            most common mutation in the transthyretin protein is p.V142I.<sup>2</sup> This mutation has origins in West Africa
            and, importantly, 3.9% of African Americans are estimated to harbor this mutation.<sup>3,4</sup>
          </p>
          <h2 className="intro-section-title font-bold">
            The Prevalence of the <span className="color-orange">p.V142I Mutation in the <span className="text-uppercase">{regionData.name}</span> Metro Area</span> Is Higher Than the National Average<sup>4,5</sup>
          </h2>
          <div className="metro-area-section">
            <div className="metro-area-row row">
              <div className="metro-area-col col-sm-4">
                <h3 className="metro-area-value font-bold">~3.9%</h3>
                <p className="metro-area-content">of African Americans are estimated <br />to have the p.V142I mutation<sup>4</sup>*</p>
              </div>
              <div className="metro-area-col col-sm-4">
                <h3 className="metro-area-value font-bold">{regionData.variable_count_1}</h3>
                <p className="metro-area-content">African Americans are living in the <br /><span className="text-uppercase">{regionData.name}</span> metro area<sup>5&#8224;</sup></p>
              </div>
              <div className="metro-area-col col-sm-4">
                <h3 className="metro-area-value font-bold">{regionData.variable_count_2}x</h3>
                <p className="metro-area-content"><span className="font-bold text-large">greater</span><br /> than the national average<sup>5&#8224;&#8225;</sup></p>
              </div>
            </div>
            <p className="metro-area-note text-center">
              *Nationally, up to an estimated 3.9% of African Americans are V142I carriers <br />
              <sup>&#8224;</sup>2017 estimated US census data <br />
              <sup>&#8225;</sup>As compared with the national average of African Americans living in metropolitan areas.
            
            </p>
            </div>
          </div>
      </div>
      <div className="testing-resource-sec text-center">
        <h3 className="testing-resource-title text-center font-bold">YOUR GENETIC TESTING RESOURCE</h3>
        <a href={`https://www.ambrygen.com/providers/order-sample-kit?source=hATTRCompass?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer" className="btn-orange"> ORDER SAMPLE KITS </a>
      </div>
      <div className="peers-section">
        <div className="container">
          <h3 className="peers-section-title font-bold text-center">
            Peer Perspectives on <span className="color-orange">hATTR Amyloidosis</span>
          </h3>
          <ul className="peers-list">
            {
              regionData !== undefined && regionData.kol_headshots_data.map((headshots, i) => {
                return (
                  <li className="peers-list-item" key={i}>
                    <React.Fragment>
                      <div className="peers-col-user">
                        <img className="peer-image img-fluid" src={require(`../../static/images/kol-headshots/${headshots.image}.png`)} alt={headshots.initials.split(',')[0]} />
                        <address className="peer-address">
                          <strong>{headshots.initials}</strong><br />
                          {headshots.credentials}<br />
                          {headshots.affiliation_1}{headshots.affiliation_2.length !== 0 && <br />}
                          {headshots.affiliation_2}{headshots.affiliation_3.length !== 0 && <br />}
                          {headshots.affiliation_3}{headshots.affiliation_4.length !== 0 && <br />}
                          {headshots.affiliation_4}{headshots.affiliation_5 && headshots.affiliation_5.length !== 0 && <br />}
                          {headshots.affiliation_5 && headshots.affiliation_5}<br/>
                          {headshots.city_state}
                        </address>
                      </div>
                      <div className="peers-col-text">
                        <p>
                        {headshots.pov}
                        </p>
                      </div>
                    </React.Fragment>
                  </li>
                )
              })
            }
          </ul>
          <p className="peers-section-note text-center">
            {regionData.kol_headshots_terms === "true" ?  <> 
            This panel of experts has not been compensated by Akcea<sup>&copy;</sup> Therapeutics for participation in this program. <br/>
            * This expert has been compensated by Akcea<sup>&copy;</sup> Therapeutics for other speaking engagements. 
            </> : <> This panel of experts has not been compensated by Akcea<sup>&reg;</sup>  Therapeutics for participation in this program, however, has been compensated by Akcea<sup>&reg;</sup> Therapeutics for other speaking engagements. 
            </> 
          }
          </p>
        </div>
      </div>
      <div className="amyloidosis-sec">
        <div className="container">
          <h3 className="amyloidosis-heading font-bold text-center">Considerations for Early Identification and Diagnosis of <span className="color-orange">hATTR Amyloidosis</span></h3>
          <div className="row amyloidosis-row">
            <div className="col col-lg-4 amyloidosis-col">
              <img className="amyloidosis-icon" src={hereditary} alt="Hereditary Nature" />
              <h4 className="amyloidosis-col-title">Hereditary Nature</h4>
              <p className="amyloidosis-text">
                The type of mutation a patient has influences their disease phenotype, resulting in varying degrees of polyneuropathy and/or cardiomyopathy.<sup>6</sup>
              </p>
              <a href={`https://www.hattrguide.com/about-hattr-amyloidosis/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer" className="btn-orange amyloidosis-btn">
                EXPLORE MUTATIONS
              </a>
            </div>
            <div className="col col-lg-4 amyloidosis-col">
              <img className="amyloidosis-icon" src={redFlag} alt="Red Flags" />
              <h4 className="amyloidosis-col-title">Red Flags</h4>
              <p className="amyloidosis-text">
                Hereditary ATTR amyloidosis should be suspected in patients with progressive, symmetric sensorimotor polyneuropathy PLUS one or more other symptoms.<sup>7</sup>
              </p>
              <a href={`https://www.hattrguide.com/hattr-symptoms/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer" className="btn-orange amyloidosis-btn">
                RECOGNIZE SYMPTOMS
              </a>
            </div>
            <div className="col col-lg-4 amyloidosis-col">
              <img className="amyloidosis-icon" src={timely} alt="Timely Diagnosis" />
              <h4 className="amyloidosis-col-title">Timely Diagnosis</h4>
              <p className="amyloidosis-text">
                A diagnostic workup is indicated in patients presenting with a cluster of symptoms. Genetic testing is one essential component in the diagnosis of hATTR amyloidosis.<sup>8,9</sup>
              </p>
              <a href={`https://www.hattrguide.com/diagnosing-hattr-amyloidosis/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer" className="btn-orange amyloidosis-btn">
                REVIEW WORKUP
              </a>
            </div>
          </div>
          <h3 className="stay-updated-heading">STAY UP TO DATE WITH NEWS ABOUT <span className="color-orange font-bold">hATTR AMYLOIDOSIS</span></h3>
          <div className="text-center">
            <a href={`https://www.hattrguide.com/register/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer" className="btn-orange register-now-btn">REGISTER NOW </a>
          </div>
        </div>
      </div>
      
    </Layout>
  )
}
