import React from 'react'
import footerLogo from "../assets/images/hattr-logo.svg";
import dataSource from "../data/region-data.json";

export default function Footer() {
    const baseUrl = typeof window !== 'undefined' ? window.location.pathname.split("/").pop() : "";
    // const changeBaseUrl = baseUrl.replace(/%20/g, ' ');
    const regionData = dataSource[baseUrl];

    const dynamicUrlTerms = baseUrl.length === 0  ? 'https://akceatx.com/terms-of-use/?utm_source=relevate&utm_medium=landing-page&utm_campaign=default&utm_term=default' : `https://akceatx.com/terms-of-use/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`;

    const dynamicUrlPrivacyPolicy = baseUrl.length === 0 ? 'https://akceatx.com/privacy-policy/?utm_source=relevate&utm_medium=landing-page&utm_campaign=default&utm_term=default' : `https://akceatx.com/privacy-policy/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`;

    const dynamicUrlContact = baseUrl.length === 0 ? 'https://akceatx.com/contact-us/?utm_source=relevate&utm_medium=landing-page&utm_campaign=default&utm_term=default' : `https://akceatx.com/contact-us/?utm_source=relevate&utm_medium=landing-page&utm_campaign=${regionData.utm_name.replace(/\s/g, "")}&utm_term=${regionData.utm_name.replace(/\s/g, "")}`;
    const dynamicReferenceSection = baseUrl.length === 0 ? <React.Fragment>
        <p>
            <span className="font-bold">References: 1.</span> Adams D, Koike H, Slama M, Coelho T. Hereditary transthyretin amyloidosis: a model of medical progress for a fatal disease. <em>Nat Rev Neurol.</em> 2019;15(7):387-404. <span className="font-bold">2.</span> Maurer MS, Hanna M, Grogan M, et al. Genotype and phenotype of transthyretin cardiac amyloidosis: THAOS (Transthyretin Amyloid Outcome Survey). <em>J Am Coll
            Cardiol.</em> 2016;68(2):161-172. <span className="font-bold">3.</span> Buxbaum J, Ruberg FL. Transthyretin V122I (pV142I)* cardiac amyloidosis: an age-dependent autosomal dominant cardiomyopathy too
            common to be overlooked as a cause of significant heart disease in elderly African Americans. <em>Genet Med.</em> 2017;19:(7)733-742. <span className="font-bold">4.</span> Ando Y, Coelho T, Berk JL, et al. Guideline of
            transthyretin-related hereditary amyloidosis for clinicians. <em>Orphanet J Rare Dis.</em> 2013;8:31.
        </p>
    </React.Fragment> : <React.Fragment>
        <p>
            <span className="font-bold">References: 1.</span> Adams D, Koike H, Slama M, Coelho T. Hereditary transthyretin amyloidosis: a model of medical progress for a fatal disease. <em>Nat Rev Neurol.</em> 2019;15(7):387-404. <span className="font-bold">2.</span> Maurer MS, Hanna M, Grogan M, et al. Genotype and phenotype of transthyretin cardiac amyloidosis: THAOS (Transthyretin Amyloid Outcome Survey). <em>J Am Coll
            Cardiol.</em> 2016;68(2):161-172. <span className="font-bold">3.</span> Buxbaum J, Ruberg FL. Transthyretin V122I (pV142I)* cardiac amyloidosis: an age-dependent autosomal dominant cardiomyopathy too
            common to be overlooked as a cause of significant heart disease in elderly African Americans. <em>Genet Med.</em> 2017;19:(7)733-742. <span className="font-bold">4.</span> Ando Y, Coelho T, Berk JL, et al. Guideline
            of transthyretin-related hereditary amyloidosis for clinicians. <em>Orphanet J Rare Dis.</em> 2013;8:31. <span className="font-bold">5.</span> U.S. Census Bureau. ACS Demographic and Housing Estimates: 2014-2018
            American Community Survey 5-Year Estimates. <a className="references-link" href="https://data.census.gov/cedsci/table?q=Table%20DP05&tid=ACSDP5Y2018.DP05" target="_blank" rel="noopener noreferrer">https://data.census.gov/cedsci/table?q=Table%20DP05&amp;tid=ACSDP5Y2018.DP05.</a> Accessed March 18, 2020. <span className="font-bold">6.</span> Gertz MA,
            Benson MD, Dyck PJ, et al. Diagnosis, prognosis, and therapy of transthyretin amyloidosis. <em>J Am Coll Cardiol.</em> 2015;66(21):2451-2466. <span className="font-bold">7.</span> Conceição I, González-Duarte A,
            Obici L, et al. “Red-flag” symptom clusters in transthyretin familial amyloid polyneuropathy. <em>J Peripher Nerv Syst.</em> 2016;21(1):5-9. <span className="font-bold">8.</span> Carvalho A, Rocha A, Lobato L. Liver
            transplantation in transthyretin amyloidosis: issues and challenges. <em>Liver Transpl.</em> 2015;21(3):282-292. <span className="font-bold">9.</span> Gillmore JD, Maurer MS, Falk RH, et al. Nonbiopsy diagnosis of cardiac
            transthyretin amyloidosis. <em>Circulation.</em> 2016;133(24):2404-2412.
        </p>
    </React.Fragment>
    return (
        <div className="footer-main-wrapper">
            <div className="references-wrapper">
                <div className="container">
                    {dynamicReferenceSection}
                </div>
            </div>
       
        <div className="footer-wrapper">
            <div className="container">
                <div className="footer-bottom-wrapper">
                    <img className="footer-logo" src={footerLogo} alt="Akcea Therapeutics" />
                    <ul className="nav-list">
                        <li><a href={dynamicUrlTerms} target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                        <li>|</li>
                        <li><a href={dynamicUrlPrivacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                        <li>|</li>
                        <li><a href={dynamicUrlContact} target="_blank" rel="noopener noreferrer">Contact</a></li>
                        <li>|</li>
                        <li>&copy; 2021 Akcea Therapeutics, Inc. All rights reserved.</li>
                        <br />
                        <li>US-TTR-2000205 Last updated 01/21</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    )
}
